import { useState } from "react";
import './App.css';
import { Route, Routes } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Privacy from "./Privacy";
import Caption from "./Caption";

function App() { 
  const [darkMode] = useState(true);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Routes>
        <Route path="/" element={<Caption />} />
        <Route path="/caption" element={<Caption />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      {/* <Router>
        <Link href="/">Link</Link>
        <Button href="/" variant="contained">
          Link
        </Button>
      </Router> */}
    </ThemeProvider>
  );
}

export default App;
