
import { useLayoutEffect, useState } from 'react';
const {processCaption} = require('./processcaption/ProcessCaption');

export function Canvas(props) {
  const [width, setWidth] = useState(500);
  const [height, setHeight] = useState(500);

  useLayoutEffect(() => {
    const canvas = props.canvasRef.current;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext('2d');
    console.log("Source Image", props.sourceImg);
    const offscreenCanvas = processCaption(props.sourceImg, {msg: props.caption, bottomMsg: props.bottomCaption, bg: props.bg, fg: props.fg, fontName: props.fontName, isBold: props.isBold, textSize: props.textSize, setTextSize: props.setTextSize, repeatEffect: props.repeatEffect, shiftInc: props.shiftInc});
    if (!offscreenCanvas) {
      return;
    }

    const img = offscreenCanvas.transferToImageBitmap();
    setWidth(img.width);
    setHeight(img.height);
    context.clearRect(0, 0, width, height);
    context.drawImage(img, 0, 0, width, height);

    return () => {
      // canvas.removeEventListener('click', clickHandler);
    };
  }, [width, height, props.sourceImg, props.caption, props.bottomCaption, props.canvasRef, props.bg, props.fg, props.fontName, props.isBold, props.textSize, props.setTextSize, props.repeatEffect, props.shiftInc]);

  return (
    <div>
      <div>
        <canvas ref={props.canvasRef} width={width} height={height} />
      </div>
    </div>
  );
}