
function createOffscreenCanvas(props, width, height) {
  if (props.createCanvas) {
    return props.createCanvas(width, height);
  }
  if (!OffscreenCanvas) {
    console.error("OffscreenCanvas is not supported, please specify a props.createCanvas function");
  }
  return new OffscreenCanvas(width, height);
}

// Function to calculate font size based on image width
function calculateFontSize(textSize, setTextSize, imageWidth) {
  if (textSize === null) {
    const fontSizeFactor = 0.1; // Adjust this factor as needed
    textSize = imageWidth * fontSizeFactor;
    if (setTextSize) {
      setTextSize(textSize);
    }
  }
  return textSize;
}

function isEmpty(value) {
  return value && Object.keys(value).length === 0;
}

function measureText(measureFix, ctx, line) {
  let metrics = ctx.measureText(line);
  metrics.lineSpace = 0;
  if (!metrics.fontBoundingBoxAscent || !metrics.fontBoundingBoxDescent) {
    let tempMetrics = ctx.measureText('Xj');
    measureFix.fontBoundingBoxAscent = tempMetrics.actualBoundingBoxAscent;
    measureFix.fontBoundingBoxDescent = tempMetrics.actualBoundingBoxDescent;
    metrics.lineSpace = tempMetrics.actualBoundingBoxDescent;
  }
  metrics.fontBoundingBoxAscent = measureFix.fontBoundingBoxAscent;
  metrics.fontBoundingBoxDescent = measureFix.fontBoundingBoxDescent;
  return metrics;
}

function produceCaption(image, caption, props, bottom) {
  if (caption === null) {
    return null;
  }
  
  // // Load the image
  var shiftInc = props.shiftInc;
  if (shiftInc) {
    shiftInc *= Math.abs(shiftInc)/4;
    shiftInc /= 200000;
  }
  const captionBg = props.bg;
  const captionFg = props.fg;

  
  // Calculate dynamic font size based on the image dimensions
  const dynamicFontSize = calculateFontSize(props.textSize, props.setTextSize, image.width);
  let fontName = props.fontName;
  if (fontName === null) {
    fontName = 'Arial'
  }
  console.log('FontName:', props.fontName);
  let font = `${dynamicFontSize}px ${fontName}`;
  
  if (props.isBold) {
    font = `700 ${font}`;
  }

  console.log('Font:', font);

  const fakeCanvas = createOffscreenCanvas(props, image.width, image.height);
  let ctx = fakeCanvas.getContext('2d');
  ctx.font = font;

  // Split the caption into multiple lines if it's too long
  const maxWidthPercentage = 90; // Adjust this percentage as needed
  const maxWidth = (image.width * maxWidthPercentage) / 100;
  const words = caption.split(' ');
  let line = '';
  let lines = [];

  for (let i = 0; i < words.length; i++) {
    const testLine = line + words[i] + ' ';
    const testWidth = ctx.measureText(testLine).width;

    if (testWidth > maxWidth) {
      lines.push(line.slice(0, -1));
      line = words[i] + ' ';
    } else {
      line = testLine;
    }
  }
  lines.push(line.slice(0, -1));

  let measureFix = {}

  // // Get height of caption for new caption canvas
  const sizeFactor = 0.075;
  const padding = image.height * sizeFactor;
  let height = padding;
  let lineSpace = 0;
  lines.forEach((line) => {
    let metrics = measureText(measureFix, ctx, line);
    height += metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent + metrics.lineSpace;
    lineSpace = metrics.lineSpace;
  });
  height += padding - lineSpace;

  // Create a second canvas for the caption
  const captionCanvas = createOffscreenCanvas(props, image.width, height);
  ctx = captionCanvas.getContext('2d');
  ctx.font = font;

  // Fill the second canvas with background color, white by default
  const repeatEffect = props.repeatEffect;
  if (repeatEffect && repeatEffect > 0) {
    let flip = true;
    const flipCanvas = createOffscreenCanvas(props, image.width, repeatEffect);
    const flipCtx = flipCanvas.getContext('2d');
    const horizontal = false;
    const vertical = flip;
    const trans_x = 0;
    const trans_y = 0;

    if (isEmpty(measureFix)) {
      let startY = (bottom) ? image.height-flipCanvas.height-1 : 0;
      flipCtx.save();  // save the current canvas state
      flipCtx.setTransform(
        horizontal ? -1 : 1, 0, // set the direction of x axis
        0, vertical ? -1 : 1,   // set the direction of y axis
        trans_x + (horizontal ? flipCanvas.width : 0), // set the x origin
        trans_y + (vertical ? flipCanvas.height : 0)   // set the y origin
      );
      flipCtx.drawImage(image, 0, startY, image.width, repeatEffect, 0, 0, image.width, repeatEffect);
      flipCtx.restore(); // restore the state as it was when this function was called
    } else {
      for (let y = 0; y < repeatEffect; y++) {
        let startY = (bottom) ? image.height-y-1 : y;
        flipCtx.drawImage(image, 0, startY, image.width, 1, 0, repeatEffect-y-1, image.width, 1);
      }
    }

    let remainder = captionCanvas.height;
    let shift = (shiftInc) ? shiftInc : 0;
    while (true)  {
      let repeatHeight = repeatEffect;//Math.min(remainder, repeatEffect);
      const sourceCanvas = (flip) ? flipCanvas : image;

      let startY = (bottom) ? (flip) ? 0 : image.height - repeatEffect : 0;
      
      if (!bottom) {
        remainder -= repeatHeight;
      }
      
      let destX1;
      let destX2 = 0;
      let destY = (bottom) ? captionCanvas.height - remainder : remainder;
      if (shiftInc && shiftInc !== 0) {
        if (shiftInc > 0) {
          destX1 = Math.round((1.0 - shift) * image.width);
          destX2 = Math.round((-shift) * image.width);
        } else {
          destX1 = Math.round((-shift) * image.width);
          destX2 = -Math.round(((1.0 + shift) * image.width));
        }
        ctx.drawImage(sourceCanvas, 0, startY, image.width, repeatHeight, destX1, destY, image.width, repeatHeight);
      }
      ctx.drawImage(sourceCanvas, 0, startY, image.width, repeatHeight, destX2, destY, image.width, repeatHeight);
      
      if (bottom) {
        remainder -= repeatHeight;
      }

      if (remainder <= 0) {
        break;
      }

      flip = !flip;
      shift += shiftInc;
      if (Math.abs(shift) > 1) {
        shift = 0;
      }
    }
  } else {
   ctx.fillStyle = captionBg;
   ctx.fillRect(0, 0, captionCanvas.width, captionCanvas.height);
  }    

  // Set the font and text properties for the caption canvas
  ctx.font = font;
  ctx.fillStyle = captionFg;
  ctx.textAlign = 'center';

  // Draw the caption text on the caption canvas
  let captionY = padding;
  lines.forEach((line) => {
    let metrics = measureText(measureFix, ctx, line);
    captionY += metrics.fontBoundingBoxAscent;

    // ctx.fillStyle = 'red';
    // let x = (captionCanvas.width - metrics.width) / 2;
    // let height =  metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    // ctx.fillRect(x, captionY - metrics.actualBoundingBoxAscent, metrics.width, height);
    // ctx.fillStyle = captionFg;

    let x = captionCanvas.width / 2;
    ctx.fillText(line, x, captionY);
    captionY += metrics.fontBoundingBoxDescent + metrics.lineSpace;// Adjusted based on font size
  });

  return captionCanvas;
}

function processCaption(image, props) {
  if (!image) {
    return;
  }

  let caption = props.msg;
  let bottomCaption = props.bottomMsg;
  console.log(caption, bottomCaption);
  if (caption != null && bottomCaption != null) {
    console.log(caption.length, bottomCaption.length);
    if (caption.length === 0 && bottomCaption.length === 0) {
      //Empty top and bottom, we will leave the top and remove the bottom.
      bottomCaption = null;
    } else if (caption.length > 0) {
      if (bottomCaption.length === 0) {
        bottomCaption = null;
      }
    } else if (bottomCaption.length > 0) {
      //An empty top and an non-empty bottom should not have a top.
      caption = null;
    }
  } else if (!caption && !bottomCaption) {
    caption = ""
    bottomCaption = null;
  }

  let captionCanvas = produceCaption(image, caption, props, false);
  let bottomCaptionCanvas = produceCaption(image, bottomCaption, props, true);
  if (!captionCanvas && !bottomCaptionCanvas) {
    captionCanvas = produceCaption(image, "", props, false);
  }

  let height = image.height;
  if (captionCanvas) {
    height += captionCanvas.height;
  }

  if (bottomCaptionCanvas) {
    height += bottomCaptionCanvas.height;
  }

  // Create a new canvas combining the original and caption canvases
  const finalCanvas = createOffscreenCanvas(props, image.width, height);
  const finalCtx = finalCanvas.getContext('2d');

  // Draw the original canvas onto the final canvas
  finalCtx.drawImage(image, 0, (captionCanvas) ? captionCanvas.height : 0);

  // Draw the caption canvas above the original canvas
  if (captionCanvas) {
    finalCtx.drawImage(captionCanvas, 0, 0);
  }

  if (bottomCaptionCanvas) {
    finalCtx.drawImage(bottomCaptionCanvas, 0, height - bottomCaptionCanvas.height - 1);
  }
  return finalCanvas;
}

module.exports = { processCaption };