import React from 'react';
import { useState, useRef } from 'react';
import './App.css';
import { Canvas } from './Canvas';
import { styled } from '@mui/material/styles';
import { Box, Button, Checkbox, CssBaseline, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, TextField, Select, Slider, Stack } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ColorizeIcon from '@mui/icons-material/Colorize';
import ReplayIcon from '@mui/icons-material/Replay';
import { MuiColorInput } from 'mui-color-input'
import { save } from 'save-file';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Caption() {
  const defaultBgColor = '#ffffff';
  const defaultFgColor = '#000000';
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('');
  const [caption, setCaption] = useState('');
  const [bottomCaption, setBottomCaption] = useState('');
  const [textSize, setTextSize] = useState(50);
  const [repeatEffect, setRepeatEffect] = useState(0);
  const [shiftInc, setShiftInc] = useState(0);
  const [darkMode] = useState(true);
  const canvasRef = useRef(null);
  const [bgValue, setBgValue] = useState(defaultBgColor);
  const [fgValue, setFgValue] = useState(defaultFgColor);
  const [sourceImg, setSourceImage] = useState(null);
  const [fontName, setFontName] = useState("Arial");
  const [isBold, setIsBold] = useState(false);

  // import { Link as RouterLink } from 'react-router-dom';
  // const LinkBehavior = React.forwardRef((props, ref) => (
  //   <RouterLink ref={ref} to='/privacy-policy.html' {...props} />
  // ));

  const fontNames = [
    'Arial',
    'Astonpoliz',
    'Beinancia',
    'BulletinGothic',
    'CaracasFina',
    'DamesplayScript',
    'HandStylus',
    'HoaxVandal',
    'OpenSauceSans',
    'PixelArmy',
    'RocksSerif',
    'SantaChild',
    'VintellaSkillton'
  ];

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const getStyles = function(name) {
    return {
      fontFamily: name
    };
  }
  
  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);

    if (file) {
      var img = new Image();
      img.onload = () => {
          setSourceImage(img);
      };
      img.src = URL.createObjectURL(file);
    } else {
        setSourceImage(null);
    }

    setTextSize(null);
    setFile(file);
  };

  const onInputClick = (e) => {
    e.target.value = '';
    setFilename(null);
  };

  const handleClipboardUpload = (e) => {
    navigator.clipboard.read()
      .then(item_list => {
        let image_type; // we will feed this later
        const item = item_list.find( item => // choose the one item holding our image 
            item.types.some( type => { // does this item have our type
              if( type.startsWith( 'image/' ) ) {
                image_type = type; // store which kind of image type it is
                return true;
              }
              return false;
            })
        );

        if (item) {
          item.getType( image_type )
            .then(file => {
              if (file) {
                var img = new Image();
                img.onload = () => {
                  setSourceImage(img);
                };
                img.src = URL.createObjectURL(file);
              } else {
                setSourceImage(null);
              }
            }
          );
        }
      } 
    ).catch ((error) => console.error(error));
  };

  const onTextChange = (e) => {
    setCaption(e.target.value);
  };

  const onBottomTextChange = (e) => {
    setBottomCaption(e.target.value);
  };

  const handleBgChange = (newValue) => {
    setBgValue(newValue);
  };

  const handleFgChange = (newValue) => {
    setFgValue(newValue);
  };

  const saveImage = async () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const imageFile = canvas.toDataURL('image/png')
    const { name } = file;
    save(imageFile, 'cap_' + name);
  };

  const resetBgColor = () => {
    setBgValue(defaultBgColor);
  };
  
  const bgColorSample = () => {
    const eyeDropper = new window.EyeDropper();
    eyeDropper
      .open()
      .then((result) => {
        setBgValue(result.sRGBHex);
      })
      .catch(resetBgColor);
  };
  
  const resetFgColor = () => {
    setFgValue(defaultFgColor);
  };

  const fgColorSample = () => {
    const eyeDropper = new window.EyeDropper();
    eyeDropper
      .open()
      .then((result) => {
        setFgValue(result.sRGBHex);
      })
      .catch(resetFgColor);
  };

  const handleSliderChange = (e, newValue) => {
    setTextSize(newValue);
  };
  
  const handleRepeatEffectSliderChange = (e, newValue) => {
    setRepeatEffect(newValue);
  };

  const handleShiftIncSliderChange = (e, newValue) => {
    setShiftInc(newValue);
  };

  const handleTextSizeTextChange = (e) => {
    setTextSize(e.target.value);
  };
  
  const handleRepeatEffectTextChange = (e) => {
    setRepeatEffect(e.target.value);
  };

  const resetRepeat = () => {
    setRepeatEffect(0);
  };

  const handleShiftIncTextChange = (e) => {
    setShiftInc(e.target.value);
  };

  const resetShiftInc = () => {
    setShiftInc(0);
  };

  const handleFontChange = (e) => {
    setFontName(e.target.value);
  };

  const handleBoldChange = (event) => {
    setIsBold(event.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <div className='App'>
        <label className='App-title'>Meme-mer</label>
        <header className='App-header'>
          {/* <Box sx={{ width: '40%', flexDirection: 'col', justifyContent: 'flex-start' }} spacing={2}> */}
          <Stack sx={{ width: '80%', flexDirection: 'col' }} direction='column' spacing={2} alignItems={'center'}>
            <Box></Box>
            <Grid container columnSpacing={0} rowSpacing={3} justifyContent='center' alignItems='start'>
              <Grid item xs={12} md={6}>
                <Grid container columnSpacing={0} rowSpacing={3} justifyContent='center' alignItems='center'>
                  <Grid item xs={0} s={0} md={0} lg={0} xl={2}/>
                  <Grid item xs={12} s={7} md={7} lg={6} xl={4}>
                    <Box sx={{height: '100%'}}>
                      <Button sx={{height: '100%'}} component='label' id='fileupload' variant='contained' startIcon={<CloudUploadIcon />} >
                        Upload file
                        <VisuallyHiddenInput type='file' onInput={handleFileUpload} onClick={onInputClick}/>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid  item xs={12} s={7} md={7} lg={6} xl={4}>
                    <Box sx={{height: '100%'}}>
                      <Button sx={{height: '100%'}} component='label' id='paste' variant='contained' onClick={handleClipboardUpload} startIcon={<ContentPasteIcon />} >
                        From Clipboard
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={0} s={0} md={0} lg={0} xl={2}/>
                  <Grid item xs={12}>
                    <Box fontSize='0.4em'>{filename}</Box>
                  </Grid>
                  <Grid item xs={12}  >
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="font-name-label">Font Name</InputLabel>
                      <Select
                        labelId="font-name-label"
                        id="font-name"
                        value={fontName}
                        onChange={handleFontChange}
                        input={<OutlinedInput label="Font Name" style={getStyles(fontName)}/>}
                        MenuProps={MenuProps}
                      >
                        {fontNames.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControlLabel value={isBold} onChange={handleBoldChange} control={<Checkbox defaultChecked={false} /> } label="Bold" />
                  </Grid>
                  <Grid item xs={0} xl={2} />
                  <Grid item xs={12} md={6} xl={4}>
                    <MuiColorInput format='hex' label='Background Color' disabled={repeatEffect !== 0} value={bgValue} onChange={handleBgChange} InputProps={{endAdornment: 
                      <Stack direction='row' spacing={0} alignItems={'center'}>
                        {
                          <IconButton style={{visibility: bgValue !== defaultBgColor ? 'visible' : 'hidden' }} disabled={repeatEffect !== 0} aria-label='reset to default' onClick={resetBgColor}><ReplayIcon /></IconButton>
                        }
                        {
                          window.EyeDropper ? <IconButton disabled={repeatEffect !== 0} aria-label='Sample color' onClick={bgColorSample}><ColorizeIcon /></IconButton> : null
                        }
                      </Stack>
                    }} />
                  </Grid>
                  <Grid item xs={12} md={6} xl={4}>
                    <MuiColorInput format='hex' label='Text Color' value={fgValue} onChange={handleFgChange} InputProps={{endAdornment: 
                      <Stack direction='row' spacing={0} alignItems={'center'}>
                        {
                          <IconButton style={{visibility: fgValue !== defaultFgColor ? 'visible' : 'hidden' }} aria-label='reset to default' onClick={resetFgColor}><ReplayIcon /></IconButton>
                        }
                        {
                          window.EyeDropper ? <IconButton aria-label='Sample color' onClick={fgColorSample}><ColorizeIcon /></IconButton> : null
                        }
                      </Stack>
                      }} />
                  </Grid>
                  <Grid item xs={0} xl={2} />

                  <Grid item xs={8} sm={3} lg={2}>
                    <TextField sx={{ width: '80%', flexDirection: 'row' }} label='Text Size' fontSize='0.4em' value={textSize} onChange={handleTextSizeTextChange} />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Slider defaultValue={30} value={textSize} onChange={handleSliderChange} max={300} min={5} />
                  </Grid>
                  <Grid item xs={8} sm={3} lg={2}>
                    <TextField sx={{ width: '80%', flexDirection: 'row' }} label='Repeat Effect' fontSize='0.4em' value={repeatEffect} onChange={handleRepeatEffectTextChange} InputProps={{endAdornment: 
                        repeatEffect !== 0 ? <IconButton aria-label='reset to default' onClick={resetRepeat}><ReplayIcon /></IconButton> : null
                    }} />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Slider defaultValue={0} value={repeatEffect} onChange={handleRepeatEffectSliderChange} max={500} min={0} />
                  </Grid>
                  <Grid item xs={8} sm={3} lg={2}>
                    <TextField sx={{ width: '80%', flexDirection: 'row' }} label='Shift Effect' disabled={repeatEffect === 0}  fontSize='0.4em' value={shiftInc} onChange={handleShiftIncTextChange} InputProps={{endAdornment: 
                        shiftInc !== 0 ? <IconButton aria-label='reset to default' onClick={resetShiftInc}><ReplayIcon /></IconButton> : null
                    }} />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Slider disabled={repeatEffect === 0} defaultValue={0} value={shiftInc} onChange={handleShiftIncSliderChange} max={1000} min={-1000} />
                  </Grid>
                  <Grid item xs={12} sm={10} xl={9}>
                    <TextField sx={{ width: '100%' }} id='outlined-basics' label='Meme Caption (Top)' variant='outlined' autoComplete= 'new-password' inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autoComplete: 'off',
                      },
                    }} onChange={onTextChange}/>
                  </Grid>
                  <Grid item xs={12} sm={10} xl={9}>
                    <TextField sx={{ width: '100%' }} id='outlined-basics' label='Meme Caption (Bottom)' variant='outlined' autoComplete= 'new-password' inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autoComplete: 'off',
                      },
                    }} onChange={onBottomTextChange}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Button color='primary' onClick={saveImage}>
                        Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Canvas 
                  canvasRef={canvasRef} 
                  sourceImg={sourceImg} 
                  width={500} height={500} 
                  file={file} 
                  fontName={fontName} 
                  isBold={isBold}
                  caption={caption} bottomCaption={bottomCaption} 
                  bg={bgValue} fg={fgValue} 
                  textSize={textSize} setTextSize={setTextSize} 
                  repeatEffect={repeatEffect} shiftInc={shiftInc} 
                />
              </Grid>
            </Grid>
          </Stack>
          {/* </Box> */}
        </header>
        {/* <Link component={LinkBehavior}>Privacy Policy</Link> */}
        <a href='/privacy'>Privacy Policy</a>
      </div>
    </ThemeProvider>
  );
}

export default Caption;
